export function parseVideoName (nameStr) {
  const info = {
    name: null,
    sensoredName: null,
    year: null,
    round: [],
    formattedInfo: null
  }
  info.name = nameStr?.trim() ?? ''
  info.sensoredName = sensorVideoName(info.name)
  info.year = tryGetYear(info.name)

  info.formattedInfo = formatInfo(info)
  return info
}

function sensorVideoName (name) {
  return name.substring(0, 20)
}

export function tryGetYear (name) {
  const yr = /\d\d\d\d/
  if (yr.test(name)) {
    const year = parseInt(yr.exec(name)[0], 10)
    if (year >= 2010 && year <= 2040) {
      return year
    }
  }
  return null
}

function formatInfo (info) {
  return `Year: ${info.year}`
}

export function isMatch (keyword, text) {
  const m = match(keyword, text)
  return m.length > 0
}

function allMatches (regex, text, phrase, multi) {
  const positions = []
  let m

  const exactRegex = new RegExp(phrase, 'i')

  while ((m = regex.exec(text)) != null) {
    const exactResult = exactRegex.exec(m[0])
    positions.push({ position: m.index + exactResult.index, value: exactResult[0] })

    if (!multi) {
      return positions
    }
  }

  return positions
}

export function match (keyword, text) {
  const matches = []

  const escape = function (s) {
    return String(s).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  const addMatches = function (regex, kw) {
    if (matches.length > 0) {
      return
    }

    const allm = allMatches(regex, text, escape(kw))
    if (allm.length > 0) {
      matches.push(...allm)
    }
  }

  const rStart = new RegExp('^' + escape(keyword) + '\\s', 'i')
  addMatches(rStart, keyword, false)

  const rEnd = new RegExp('\\s' + escape(keyword) + '$', 'i')
  addMatches(rEnd, keyword, false)

  const rContains = new RegExp('\\s' + escape(keyword) + '\\s', 'gi')
  addMatches(rContains, keyword, true)

  const rContainsWithSeparator = new RegExp('\\s' + escape(keyword + ',') + '\\s', 'gi')
  addMatches(rContainsWithSeparator, keyword + ',', true)

  return matches
}

function matchesAnyInternal (keywords, text) {
  const result = { isMatch: false, matchCount: 0, matchValues: [] }

  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i]
    const matches = match(keyword, text)
    for (let j = 0; j < matches.length; j++) {
      const m = matches[j]
      result.isMatch = true
      result.matchCount++
      result.matchValues.push({ value: m.value, position: m.position })
    }
  }

  return result
}

export function matchesAny (keywords, text) {
  const result = matchesAnyInternal(keywords, text)

  return result.isMatch ? result.matchValues[0].value : null
}

export function matchCount (keywords, text) {
  const result = matchesAnyInternal(keywords, text)

  return result.matchCount
}
