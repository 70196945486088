<template>
  <div></div>
</template>

<script>
export default {
  name: 'RecordSelections',
  props: {
    listRef: {
      type: HTMLTableElement
    },
    channelId: {
      type: [String, Number]
    },
    page: {
      type: Number
    }
  },
  data () {
    return {
      keywords: []
    }
  },
  watch: {
    page () {
      this.setupKeywordSearch()
    }
  },
  mounted () {
    window.addEventListener('keydown', this.cmdUp, true)
    this.setupKeywordSearch()
  },
  destroyed () {
    window.removeEventListener('keydown', this.cmdUp, true)
  },
  methods: {
    cmdUp (e) {
      if (e.key === 'a' && e.altKey) {
        this.addKeyword()
      } else if (e.key === 'z' && e.altKey) {
        this.undoLast()
      }
    },
    addKeyword () {
      const selection = window.getSelection().toString().trim()
      if (selection.length > 0) {
        if (!this.keywords.includes(selection)) {
          this.keywords.push(selection)
          this.setupKeywordSearch()
          this.print()
        }
      }
    },
    undoLast () {
      if (this.keywords.length > 0) {
        this.keywords.pop()
        this.setupKeywordSearch()
        this.print()
      }
    },
    print () {
      console.log(this.keywords)
    },
    setupKeywordSearch () {
      this.$el.innerHTML = ''
      setTimeout(() => {
        this.doKeywordSearch()
      }, 300)
    },
    doKeywordSearch () {
      if (this.listRef == null) {
        return
      }

      const tds = Array.from(this.listRef.querySelectorAll('td'))
      if (tds.length === 0) {
        return
      }

      tds.forEach(td => {
        const match = this.getFirstMatch(td.textContent)
        if (match != null) {
          const el = document.createElement('div')
          el.classList.add('keyword-highlight')
          el.innerText = match
          el.style.position = 'absolute'
          el.style.left = '-110px'
          el.style.top = (td.offsetTop + 72) + 'px'
          this.$el.append(el)
        }
      })
    },
    getFirstMatch (text) {
      for (const kw of this.keywords) {
        if (this.isMatch(kw, text)) {
          return kw
        }
      }
      return null
    },
    isMatch (keyword, text) {
      const escape = function (s) {
        return String(s).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&')
      }
      const t = text
      const rStart = new RegExp('^' + escape(keyword) + '\\s', 'i')
      if (rStart.test(t)) {
        return true
      }

      const rEnd = new RegExp('\\s' + escape(keyword) + '$', 'i')
      if (rEnd.test(t)) {
        return true
      }

      const rContains = new RegExp('\\s' + escape(keyword) + '\\s', 'i')
      if (rContains.test(t)) {
        return true
      }

      const rContainsWithSeparator = new RegExp('\\s' + escape(keyword + ',') + '\\s', 'i')
      if (rContainsWithSeparator.test(t)) {
        return true
      }

      return false
    }
  }
}
</script>
