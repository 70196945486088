<template>
  <div>
    <yt-player :id="externalId" />
  </div>
</template>
<script>
import YtPlayer from '../components/YtPlayer.vue'

export default {
  name: 'ItemDetails',
  components: {
    YtPlayer
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      videoData: null
    }
  },
  computed: {
    externalId () {
      return this.videoData?.channelVideoExternalId
    }
  },
  mounted () {
    this.loadVideo()
  },
  methods: {
    loadVideo () {
      this.fetchVideo()
        .then(data => {
          this.videoData = data
        })
    },
    fetchVideo () {
      return this.$api.get('/api/ytlist/playlist/item-details/' + this.id)
        .then(response => {
          return response.data
        })
    }
  }
}
</script>
