<template>
  <div>
    <rp-button @click="gotoAdd">Add</rp-button>
    <h3 class="title is-3">Past events</h3>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ev, ix) in pastEvents" :key="ix">
          <td><router-link :to="ev.link">{{ ev.name }}</router-link></td>
          <td>{{ formatDate(ev.startAt) }}</td>
        </tr>
      </tbody>
    </table>
    <rp-button @click="morePastEvents">More</rp-button>

    <h3 class="title is-3">Upcoming events</h3>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ev, ix) in upcomingEvents" :key="ix">
          <td><router-link :to="ev.link">{{ ev.name }}</router-link></td>
          <td>{{ formatDate(ev.startAt) }}</td>
        </tr>
      </tbody>
    </table>
    <rp-button @click="moreUpcomingEvents">More</rp-button>
  </div>
</template>

<script>
import orderBy from 'lodash.orderby'

export default {
  name: 'EventList',
  data () {
    return {
      events: [],
      pastPage: 1,
      upcomingPage: 1
    }
  },
  computed: {
    pastEvents () {
      const eventsSorted = orderBy(this.events, ['endAtTs'], ['desc'])
      return eventsSorted.reduce((events, event) => {
        if (events.length >= this.pastPage * 10) {
          return events
        }

        const now = new Date().getTime()
        const d = new Date(event.endAt)
        if (d.getTime() < now) {
          events.push(event)
        }

        return events
      }, [])
    },
    upcomingEvents () {
      const eventsSorted = orderBy(this.events, ['endAtTs'], ['asc'])
      return eventsSorted.reduce((events, event) => {
        if (events.length >= this.upcomingPage * 10) {
          return events
        }

        const now = new Date().getTime()
        const d = new Date(event.endAt)
        if (d.getTime() > now) {
          events.push(event)
        }

        return events
      }, [])
    }
  },
  mounted () {
    this.fetchEvents()
  },
  methods: {
    fetchEvents () {
      this.$api.get('/api/ytlist/event')
        .then(response => {
          this.events = response.data.events
            .map(x => {
              const link = { name: 'ytlist/event-details', params: { id: x.id } }
              return {
                endAtTs: this.convertToTs(x.endAt),
                ...x,
                link
              }
            })
        })
    },
    gotoAdd () {
      this.$router.push({ name: 'ytlist/event-create' })
    },
    convertToTs (dStr) {
      if (dStr == null) {
        return 0
      }

      const d = new Date(dStr)
      return d.getTime()
    },
    formatDate (d) {
      return d?.substring(0, 10) ?? null
    },
    morePastEvents () {
      this.pastPage++
    },
    moreUpcomingEvents () {
      this.upcomingPage++
    }
  }
}
</script>
