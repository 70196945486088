<template>
  <div>
    <div>
      <rp-form-field v-model="query" label="Query" />
      <rp-button @click="search" :loading="searchLoading">Search</rp-button>
    </div>
    <search-items :searchData="currentSearchData" @loadPage="loadPage" />
  </div>
</template>

<script>
import SearchItems from './SearchItems.vue'

export default {
  name: 'SearchChannel',
  components: {
    SearchItems
  },
  data () {
    return {
      query: null,
      currentSearchData: null,
      currentPageToken: null,
      searchLoading: false
    }
  },
  mounted () {
  },
  methods: {
    search () {
      this.searchLoading = true
      const body = {
        query: this.query,
        maxResults: 20,
        pageToken: this.currentPageToken
      }
      this.$api.post('/api/ytlist/channels-search', body)
        .then(response => {
          this.currentSearchData = response.data
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    loadPage (pageToken) {
      this.currentPageToken = pageToken
      this.search()
    }
  }
}
</script>
