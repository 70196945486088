<template>
  <div>
    <yt-player :id="id" />
    <div><a v-if="showAdd" href="#" @click.prevent="addToDefault">Add to default playlist</a></div>
  </div>
</template>
<script>
import YtPlayer from './components/YtPlayer.vue'

export default {
  name: 'YtVideoDetails',
  components: {
    YtPlayer
  },
  props: {
    id: String
  },
  data () {
    return {
      showAdd: true
    }
  },
  methods: {
    addToDefault () {
      this.showAdd = false
      const data = {
        externalId: this.id
      }
      return this.$api.post('/api/ytlist/sync-video', data)
        .then(response => {
          return response.data
        })
    }
  }
}
</script>
