<template>
  <div>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(channel, ix) in channels" :key="ix">
          <td><router-link :to="channel.link">{{ channel.name }}</router-link></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Channels',
  data () {
    return {
      channels: []
    }
  },
  mounted () {
    this.fetchChannels()
  },
  methods: {
    fetchChannels () {
      this.$api.get('/api/ytlist/channels')
        .then(response => {
          this.channels = response.data.channels
            .map(c => {
              const link = { name: 'ytlist/channel-details', params: { id: c.id } }
              return {
                ...c,
                link
              }
            })
        })
    }
  }
}
</script>
