<template>
  <div>
    <rp-form-field v-model="name" label="Name" />
    <rp-form-field v-model="externalId" label="External ID" />
    <a v-if="externalId != null" href="#" @click.prevent="fetchData">Fetch data</a>
    <rp-form-field v-model="division" label="Division" />
    <div class="division-select">
      <a v-for="(d, ix) in availableDivisions" :key="ix" href="#" @click.prevent="setDivision(d)">{{ d }}</a>
    </div>
    <rp-button color="primary" :loading="loading" @click="create">Create</rp-button>
  </div>
</template>

<script>
export default {
  name: 'EventCreate',
  data () {
    return {
      name: null,
      externalId: null,
      division: null,
      loading: false,
      availableDivisions: []
    }
  },
  methods: {
    create () {
      if (this.name == null || this.name === '') {
        return
      }
      if (this.externalId == null || this.externalId === '') {
        return
      }
      if (this.division == null || this.division === '') {
        return
      }

      const model = {
        name: this.name,
        externalId: this.externalId,
        division: this.division
      }
      this.loading = true
      this.$api.post('/api/ytlist/event', model)
        .then(response => {
          this.loading = false
          this.$router.replace({ name: 'ytlist/event-details', params: { id: response.data.id } })
        })
    },
    fetchData () {
      this.loading = true
      this.$api.get('/api/ytlist/event/event-data/' + this.externalId)
        .then(response => {
          const eventData = JSON.parse(response.data.eventData)
          this.populateForm(eventData)
          this.loading = false
        })
    },
    populateForm (eventData) {
      if (eventData.data?.Divisions != null) {
        this.availableDivisions = eventData.data.Divisions.map(d => d.Division)
      }
      if (eventData.data?.Name != null && this.name === null) {
        this.name = eventData.data.Name
      }
    },
    setDivision (division) {
      this.division = division
    }
  }
}
</script>

<style scoped>
.division-select {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 32px;
}
</style>
