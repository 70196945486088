<template>
  <div>
    <yt-video
      v-if="hasExternalId"
      :id="externalId"
      :startAt="itemProgress"
      @currentTime="currentTimeChange"
      @progress="progressChange" />
    <fieldset>
      <a :href="ytlink" target="_blank">
        Open video
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <path fill="#fff" stroke="#36c" d="M1.5 4.518h5.982V10.5H1.5z"/>
          <path fill="#36c" d="M5.765 1H11v5.39L9.427 7.937l-1.31-1.31L5.393 9.35l-2.69-2.688 2.81-2.808L4.2 2.544z"/>
          <path fill="#fff" d="m9.995 2.004.022 4.885L8.2 5.07 5.32 7.95 4.09 6.723l2.882-2.88-1.85-1.852z"/>
        </svg>
      </a>
    </fieldset>
  </div>
</template>
<script>
import YtVideo from './YtVideo.vue'

export default {
  name: 'YtPlayer',
  components: {
    YtVideo
  },
  props: {
    id: String // ExternalId
  },
  data () {
    return {
      userItemInfo: null
    }
  },
  computed: {
    externalId () {
      return this.id
    },
    hasExternalId () {
      return this.externalId != null
    },
    ytlink () {
      return this.hasExternalId
        ? 'https://www.youtube.com/watch?v=' + this.externalId
        : null
    },
    watched () {
      return this.userItemInfo?.watched ?? false
    },
    itemProgress () {
      return this.userItemInfo?.progress ?? 0
    }
  },
  watch: {
    hasExternalId () {
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      if (this.userItemInfo === null && this.hasExternalId) {
        this.fetchPlaylistItemUserInfo()
      }
    },
    currentTimeChange (e) {
      // console.log('currentTimeChange', e)
    },
    progressChange (prog) {
      if (!this.watched) {
        this.saveProgress(prog)
      }
    },
    saveProgress (progress) {
      const data = {
        externalId: this.externalId,
        progress
      }
      this.$api.post('/api/ytlist/user-settings/progress/', data)
    },
    fetchPlaylistItemUserInfo () {
      this.$api.get('/api/ytlist/user-settings/progress/' + this.id)
        .then(response => {
          this.userItemInfo = response.data
        })
    }
  }
}
</script>
