<template>
  <div>
    <div>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a :disabled="!hasPrevPage" @click="prev" class="pagination-previous">&lt; Prev page</a>
        <a :disabled="!hasNextPage" @click="next" class="pagination-next">Next page &gt;</a>
      </nav>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Position</th>
          <th>Title</th>
          <th>Published</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="hasItems">
        <tr v-for="item in playlistData.items" :key="item.id">
          <td>{{ item.snippet.position }}</td>
          <td class="playlist-title"><censored-name :censored="applyCensor" :name="item.snippet.title" /></td>
          <td>{{ formatDate(item.snippet.publishedAt) }}</td>
          <td><rp-button :link="getVideoLink(item)" mode="ICON" iconSize="small" iconStyle="fab" icon="youtube" :hideLabel="true" @click.prevent="showVideo(item)" /></td>
        </tr>
      </tbody>
    </table>
    <div>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a :disabled="!hasPrevPage" @click="prev" class="pagination-previous">&lt; Prev page</a>
        <a :disabled="!hasNextPage" @click="next" class="pagination-next">Next page &gt;</a>
      </nav>
    </div>
  </div>
</template>

<script>
import CensoredName from '../components/CensoredName.vue'

export default {
  inheritAttrs: false,
  components: {
    CensoredName
  },
  props: {
    playlistData: Object,
    applyCensor: Boolean
  },
  computed: {
    hasItems () {
      return this.playlistData?.items != null
    },
    prevPageToken () {
      return this.playlistData?.prevPageToken
    },
    hasPrevPage () {
      return this.prevPageToken != null
    },
    nextPageToken () {
      return this.playlistData?.nextPageToken
    },
    hasNextPage () {
      return this.nextPageToken != null
    }
  },
  methods: {
    prev () {
      this.$emit('loadPage', this.prevPageToken)
    },
    next () {
      this.$emit('loadPage', this.nextPageToken)
    },
    showVideo (v) {
      this.$router.push({ name: 'ytlist/ytvideo', params: { id: v.contentDetails.videoId } })
    },
    getVideoLink (v) {
      const props = this.$router.resolve({
        name: 'ytlist/ytvideo',
        params: { id: v.contentDetails.videoId }
      })

      return props.href
    },
    formatDate (d) {
      return d.substring(0, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.playlist-title {
  white-space: nowrap;
  max-width: 700px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
</style>
