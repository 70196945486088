<template>
  <div></div>
</template>

<script>
export default {
  name: 'YtApi',
  mounted () {
    const scriptElement = document.createElement('script')
    scriptElement.src = 'https://www.youtube.com/iframe_api'
    document.body.append(scriptElement)
  }
}
</script>
