<template>
  <div>
    Par {{ holePar }}. Average: {{ holeAverage }} ({{ holeDifficulty }})
  </div>
</template>

<script>
import orderBy from 'lodash.orderby'

export default {
  name: 'HoleStats',
  props: {
    round: Object,
    holeTotal: Number,
    hole: Number
  },
  computed: {
    holeAverages () {
      return this.round.holes.reduce((holes, hole, holeIx) => {
        const par = this.round.holes[holeIx].Par
        const avg = this.getHoleAverage(holeIx)
        const info = {
          holeIx,
          par,
          avg,
          parDiff: avg - par
        }
        holes.push(info)
        return holes
      }, [])
    },
    holePar () {
      const hole = this.hole === this.holeTotal ? this.hole - 1 : this.hole
      return this.holeAverages[hole].par
    },
    parDiff () {
      const hole = this.hole === this.holeTotal ? this.hole - 1 : this.hole
      return this.holeAverages[hole].parDiff
    },
    holeAverage () {
      const hole = this.hole === this.holeTotal ? this.hole - 1 : this.hole
      return this.holeAverages[hole].avg
    },
    holeDifficulty () {
      const hole = this.hole === this.holeTotal ? this.hole - 1 : this.hole
      const sorted = orderBy(this.holeAverages, ['parDiff'], ['desc'])
      return sorted.findIndex(h => h.holeIx === hole) + 1
    }
  },
  methods: {
    getHoleAverage (holeIx) {
      const results = this.round.scores.reduce((results, score) => {
        const scores = score.Scores.split(',').map(x => parseInt(x))

        let teeStart = parseInt(score.TeeStart)
        if (isNaN(teeStart)) {
          teeStart = 1
        }
        teeStart = teeStart - 1

        const ix = (holeIx + teeStart) % 18
        const holeScore = scores[ix]
        if (!isNaN(holeScore)) {
          results.push(holeScore)
        }

        return results
      }, [])

      const sum = results.reduce((sum, res) => sum + res, 0)
      return (sum / results.length).toFixed(2)
    }
  }
}
</script>
