<template>
  <div>
    <div>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a :disabled="!hasPrevPage" @click="prev" class="pagination-previous">&lt; Prev page</a>
        <a :disabled="!hasNextPage" @click="next" class="pagination-next">Next page &gt;</a>
      </nav>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th></th>
          <th>Id</th>
          <th>Title</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody v-if="hasItems">
        <tr v-for="item in searchData.items" :key="item.id.channelId">
          <td><img v-if="hasThumbnail(item.snippet)" :src="getThumbnail(item.snippet)" class="channel-thumbnail" /></td>
          <td>
            {{ item.snippet.channelId }}
            <rp-button :loading="addLoading" @click="addChannel(item)">Add</rp-button>
          </td>
          <td>{{ item.snippet.title }}</td>
          <td class="channel-description">{{ item.snippet.description }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a :disabled="!hasPrevPage" @click="prev" class="pagination-previous">&lt; Prev page</a>
        <a :disabled="!hasNextPage" @click="next" class="pagination-next">Next page &gt;</a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchItems',
  props: {
    searchData: Object
  },
  data () {
    return {
      addLoading: false
    }
  },
  computed: {
    hasItems () {
      return this.searchData?.items != null
    },
    prevPageToken () {
      return this.searchData?.prevPageToken
    },
    hasPrevPage () {
      return this.prevPageToken != null
    },
    nextPageToken () {
      return this.searchData?.nextPageToken
    },
    hasNextPage () {
      return this.nextPageToken != null
    }
  },
  methods: {
    prev () {
      this.$emit('loadPage', this.prevPageToken)
    },
    next () {
      this.$emit('loadPage', this.nextPageToken)
    },
    hasThumbnail (snippet) {
      return snippet.thumbnails?.default != null
    },
    getThumbnail (snippet) {
      return snippet.thumbnails.default.url
    },
    addChannel (item) {
      this.addLoading = true
      const body = {
        channelId: item.snippet.channelId
      }
      this.$api.post('/api/ytlist/ensure-channel', body)
        .finally(() => {
          this.addLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-description {
  white-space: nowrap;
  max-width: 700px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.channel-thumbnail {
  max-width: 100px;
}
</style>
