<template>
  <div v-if="youtubeReady" class="iframe-wrapper">
    <div :id="wrapperId"></div>
  </div>
</template>
<script>
import uniqueId from './uniqueId'

export default {
  name: 'YtVideo',
  props: {
    id: String,
    startAt: Number
  },
  data () {
    return {
      uuid: uniqueId(),
      initialized: false,
      player: null,
      progressTimer: null,
      initialStarted: false
    }
  },
  computed: {
    wrapperId () {
      return 'iframe-wrapper' + this.uuid
    },
    youtubeReady () {
      return this.$store.state.ytlist.youtubeReady
    },
    videoSource () {
      return 'https://www.youtube.com/embed/' + this.id
    }
  },
  watch: {
    youtubeReady () {
      this.$nextTick(() => {
        this.init()
      })
    }
  },
  mounted () {
    if (this.youtubeReady) {
      this.init()
    }
  },
  destroyed () {
    clearInterval(this.progressTimer)
  },
  methods: {
    init () {
      if (this.initialized) {
        return
      }

      this.player = new this.$youtube.YT.Player(
        this.wrapperId,
        {
          height: '100%',
          width: '100%',
          videoId: this.id,
          host: 'https://www.youtube-nocookie.com',
          playerVars: {
            playsinline: 1,
            origin: window.location.host,
            autoplay: 1
          },
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.onPlayerStateChange
          }
        }
      )
    },
    onPlayerReady () {
    },
    onPlayerStateChange (event) {
      // PlayerState: { BUFFERING: 3, CUED: 5, ENDED: 0, PAUSED: 2 PLAYING: 1, UNSTARTED: -1 }
      if (event.data === this.$youtube.YT.PlayerState.PLAYING) {
        const duration = this.player.getDuration()
        this.progressTimer = setInterval(() => {
          this.emitProgress()
        }, 10000)

        if (!this.initialStarted) {
          this.initialStarted = true
          if (this.startAt !== 0) {
            const moveToTime = Math.floor(duration * this.startAt)
            this.player.seekTo(moveToTime, true)
          }
        }
      } else {
        clearTimeout(this.progressTimer)
      }

      if (event.data === this.$youtube.YT.PlayerState.PAUSED) {
        this.emitProgress()
      }
    },
    emitProgress () {
      const duration = this.player.getDuration()
      const currentTime = this.player.getCurrentTime()
      const durationPercentage = Math.floor(currentTime / duration * 10000) / 10000
      this.$emit('currentTime', currentTime)
      this.$emit('progress', durationPercentage)
    }
  }
}
</script>
