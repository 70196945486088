<template>
  <div>
    <router-link class="button" v-for="round in roundLinks" :key="round.id" :to="round.link">{{ round.name }}</router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RoundSummary',
  props: {
    id: [String, Number]
  },
  computed: {
    ...mapGetters(
      'ytlist/currentEvent', {
        info: 'getEventInfo',
        eventData: 'getEventData',
        roundsData: 'getRoundsData'
      }
    ),
    roundLinks () {
      return this.roundsData.map((r, ix) => {
        return {
          id: r.data.live_round_id,
          name: 'Round ' + (ix + 1),
          link: `/ytlist/event/${this.id}/round/${r.data.live_round_id}`
        }
      })
    }
  }
}
</script>
