<template>
  <table class="table is-bordered is-narrow">
    <tr v-for="kvp in dictionary" :key="kvp.key">
      <td class="has-text-right has-background-highlight">{{ kvp.key }}</td>
      <td>{{ kvp.value }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'DictionaryTable',
  props: {
    dictionary: {
      type: Array
    }
  }
}
</script>
