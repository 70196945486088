<template>
  <div class="round-scores">
    <span v-for="(score, ix) in scoresInternal" :key="ix" :class="scoreClasses(score)" class="score"></span>
  </div>
</template>

<script>
export default {
  name: 'RoundScores',
  props: {
    scores: Array,
    roundHoleCount: Number
  },
  computed: {
    scoresInternal () {
      const halfCount = this.roundHoleCount != null ? this.roundHoleCount / 2 : 9

      const scores = []
      for (let i = 0; i < this.scores.length; i++) {
        const score = this.scores[i]

        if (i === halfCount) {
          scores.push({ isSpace: true, isHalf: true })
        } else if ((i % 3) === 0 && i > 0) {
          scores.push({ isSpace: true })
        }

        scores.push(score)
      }
      return scores
    }
  },
  methods: {
    scoreClasses (score) {
      if (score.isSpace) {
        return {
          space: true,
          half: score.isHalf
        }
      }

      return {
        ace: score.throws === 1,
        albatross: score.parDiff === -3,
        eagle: score.parDiff === -2,
        birdie: score.parDiff === -1,
        par: score.parDiff === 0,
        bogey: score.parDiff === 1,
        dblbogey: score.parDiff === 2,
        tripbogey: score.parDiff === 3,
        quadbogey: score.parDiff === 4,
        fuckup: score.parDiff > 4
      }
    }
  }
}
</script>
