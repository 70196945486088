<template>
  <div>
    <rp-form-field v-model="title" label="Title" />
    <div><textarea class="is-family-monospace is-size-7 textarea" rows="20" v-model="data"></textarea></div>
    <rp-button color="primary" :loading="loading" @click="update">Update</rp-button>
  </div>
</template>

<script>
export default {
  name: 'NotesEdit',
  props: {
    id: [String, Number]
  },
  data () {
    return {
      info: null,
      title: null,
      data: null,
      loading: false
    }
  },
  mounted () {
    this.fetchNotes()
  },
  methods: {
    fetchNotes () {
      return this.$api.get('/api/ytlist/notes/' + this.id)
        .then(response => {
          this.title = response.data.title
          this.data = response.data.data
          this.info = response.data
        })
    },
    update () {
      if (this.title == null || this.title === '') {
        return
      }

      const model = {
        id: this.id,
        title: this.title,
        data: this.data
      }
      this.loading = true
      this.$api.put('/api/ytlist/notes/' + this.id, model)
        .then(response => {
          this.loading = false
          this.$router.replace({ name: 'ytlist/notes-details', props: { id: this.id } })
        })
    }
  }
}
</script>
