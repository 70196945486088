<template>
  <div>
    <rp-button @click="gotoAdd">Add</rp-button>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(note, ix) in notes" :key="ix">
          <td><router-link :to="note.link">{{ note.title }}</router-link></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'NotesList',
  data () {
    return {
      notes: []
    }
  },
  mounted () {
    this.fetchNotes()
  },
  methods: {
    fetchNotes () {
      this.$api.get('/api/ytlist/notes')
        .then(response => {
          this.notes = response.data.notes
            .map(x => {
              const link = { name: 'ytlist/notes-details', params: { id: x.id } }
              return {
                ...x,
                link
              }
            })
        })
    },
    gotoAdd () {
      this.$router.push({ name: 'ytlist/notes-create' })
    }
  }
}
</script>
