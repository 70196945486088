<template>
  <div>
    <rp-form-field v-model="name" label="Name" />
    <rp-button color="primary" :loading="loading" @click="create">Create</rp-button>
  </div>
</template>

<script>
export default {
  name: 'PlaylstCreate',
  data () {
    return {
      name: null,
      loading: false
    }
  },
  methods: {
    create () {
      if (this.name == null || this.name === '') {
        return
      }

      const model = {
        name: this.name
      }
      this.loading = true
      this.$api.post('/api/ytlist/playlist/create', model)
        .then(response => {
          this.loading = false
          this.$router.push({ name: 'ytlist/playlist-list' })
        })
    }
  }
}
</script>
