<template>
  <div class="home">
    <rp-button
      v-for="channel in channels"
      :key="channel.id"
      :loading="listPending"
      :label="channel.name"
      @click="list(channel)" />
    <div>
      <rp-button @click="sync" :loading="syncLoading">Sync</rp-button>
    </div>
    <div><rp-checkbox label="Censor" v-model="applyCensor" /></div>
    <channel-details
      :channel="currentChannel"
      :playlistData="currentPlaylistData"
      :applyCensor="applyCensor"
      @loadPage="loadPage" />
    <pre>{{ info }}</pre>
    <pre>{{ err }}</pre>
  </div>
</template>

<script>
import ChannelDetails from './ChannelDetails.vue'

// Try: https://developers.google.com/youtube/v3/docs/channels/list?apix_params=%7B%22part%22%3A%5B%22contentDetails%22%5D%2C%22id%22%3A%5B%22UCmGyCEbHfY91NFwHgioNLMQ%22%5D%7D
export default {
  name: 'Sync',
  components: {
    ChannelDetails
  },
  data () {
    return {
      channels: [],
      info: null,
      err: null,
      loaders: 0,
      currentChannel: null,
      currentChannelId: null,
      currentPlaylist: null,
      currentPlaylistData: null,
      currentPageToken: null,
      syncLoading: false,
      applyCensor: true
    }
  },
  computed: {
    listPending () {
      return this.loaders > 0
    }
  },
  mounted () {
    this.fetchChannels()
  },
  methods: {
    sync () {
      this.syncLoading = true
      const body = {
        channelId: this.currentChannelId,
        playlistId: this.currentPlaylist,
        pageToken: this.currentPageToken
      }
      this.$api.post('/api/ytlist/sync-channel-videos', body)
        .then(response => {
          this.info = response.data
        })
        .finally(() => {
          this.syncLoading = false
        })
    },
    listContentDetails (channelId) {
      this.addLoader()
      const body = {
        channelId,
        parts: ['id', 'contentDetails']
      }
      return this.$api.post('/api/ytlist/channels-list', body)
        .then(response => {
          return response.data
        })
        .catch(err => {
          this.addError(err)
          throw err
        })
        .finally(() => {
          this.removeLoader()
        })
    },
    listVideos (playlistId, pageToken) {
      this.addLoader()
      const body = {
        playlistId,
        pageToken,
        maxResults: 50,
        parts: ['id', 'contentDetails', 'snippet']
      }
      return this.$api.post('/api/ytlist/playlistItems-list', body)
        .catch(err => {
          this.addError(err)
          throw err
        })
        .then(response => {
          return response.data
        })
        .finally(() => {
          this.removeLoader()
        })
    },
    fetchChannels () {
      this.$api.get('/api/ytlist/channels')
        .then(response => {
          this.channels = response.data.channels
        })
    },
    list (ch) {
      this.currentChannelId = ch.externalId
      this.currentChannel = ch
      this.listUploads(this.currentChannelId)
    },
    listUploads (channelId) {
      this.listContentDetails(channelId)
        .then(data => {
          const ulId = data.items.find(i => i.id === channelId)?.contentDetails.relatedPlaylists.uploads
          if (ulId == null) {
            this.addError('Found no uploads id')
            console.log(data)
            throw Error('Found no uploads id')
          }
          return ulId
        })
        .then(ulId => {
          this.currentPlaylist = ulId
          return this.loadVideos(ulId)
        })
        .then(listData => {
          this.info = listData
        })
    },
    loadPage (pageToken) {
      this.loadVideos(this.currentPlaylist, pageToken)
    },
    loadVideos (playlistId, pageToken) {
      this.currentPageToken = pageToken ?? null
      this.listVideos(playlistId, pageToken)
        .then(data => {
          this.currentPlaylistData = data
        })
    },
    async login () {
      await this.$auth.login()
    },
    addLoader () {
      this.loaders++
    },
    removeLoader () {
      this.loaders--
    },
    addError (e) {
      let err = this.err + '\n\n' ?? ''
      err += e
      this.err = err
    }
  }
}
</script>
