<template>
  <div>
    <rp-form-field v-model="name" label="Name" />
    <div><rp-checkbox v-model="isDefault" label="Default" /></div>
    <div><rp-checkbox v-model="isCensored" label="Censored" /></div>
    <div><rp-checkbox v-model="isDone" label="Done" /></div>
    <rp-button color="primary" :loading="loading" @click="update">Update</rp-button>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th colspan="2">Playlist items</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, ix) in playlistItems" :key="ix">
          <td>
            <div><censored-name :censored="isCensored" :name="item.name" /></div>
            <div>{{ item.channelName }}</div>
            <div><rp-button @click="remove(item)">Remove video</rp-button></div>
          </td>
          <td class="item-sort">
            <rp-button @click="sortUp(ix)" :disabled="!canSortUp(ix)" mode="ICON" icon="arrow-up" />
            <rp-button @click="sortDown(ix)" :disabled="!canSortDown(ix)" mode="ICON" icon="arrow-down" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CensoredName from '../components/CensoredName'

export default {
  name: 'PlaylstCreate',
  components: {
    CensoredName
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      info: null,
      name: null,
      isDefault: false,
      isCensored: false,
      isDone: false,
      loading: false
    }
  },
  computed: {
    playlistItems () {
      return this.info?.items ?? []
    }
  },
  mounted () {
    this.fetchPlaylist()
    this.fetchPlaylistUserInfo()
  },
  methods: {
    fetchPlaylist () {
      return this.$api.get('/api/ytlist/playlist/details/' + this.id)
        .then(response => {
          this.name = response.data.name
          this.info = response.data
        })
    },
    fetchPlaylistUserInfo () {
      this.$api.get('/api/ytlist/playlist/list-user-props/' + this.id)
        .then(response => {
          this.isDefault = response.data.isDefault
          this.isCensored = response.data.isCensored
          this.isDone = response.data.isDone
        })
    },
    update () {
      if (this.name == null || this.name === '') {
        return
      }

      const model = {
        id: this.id,
        name: this.name,
        isDefault: this.isDefault,
        isCensored: this.isCensored,
        isDone: this.isDone
      }
      this.loading = true
      this.$api.post('/api/ytlist/playlist/update', model)
        .then(response => {
          this.loading = false
          this.$router.replace({ name: 'ytlist/playlist-details', props: { id: this.id } })
        })
    },
    remove (item) {
      this.$api.delete('/api/ytlist/playlist/item/' + item.id)
        .then(() => {
          this.fetchPlaylist()
        })
    },
    canSortUp (ix) {
      return ix > 0
    },
    canSortDown (ix) {
      return ix < this.playlistItems.length - 1
    },
    sortUp (ix) {
      const item = this.playlistItems[ix]
      const prevItem = this.playlistItems[ix - 1]

      this.swapSort(item, prevItem)
    },
    sortDown (ix) {
      const item = this.playlistItems[ix]
      const nextItem = this.playlistItems[ix + 1]

      this.swapSort(item, nextItem)
    },
    swapSort (item1, item2) {
      this.loading = true
      return Promise.all([
        this.$api.post('/api/ytlist/playlist/sort-item', { id: item1.id, sortOrder: item2.sortOrder }),
        this.$api.post('/api/ytlist/playlist/sort-item', { id: item2.id, sortOrder: item1.sortOrder })
      ])
        .then(() => {
          this.fetchPlaylist()
            .then(() => {
              this.loading = false
            })
        })
    }
  }
}
</script>
