<template>
  <div>
    <rp-button @click="gotoAdd">Add</rp-button>
    <h3 class="title is-3">Current playlists</h3>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>Default</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(playlist, ix) in currentPlaylists" :key="ix">
          <td><router-link :to="playlist.link">{{ playlist.name }}</router-link></td>
          <td class="col-icon">{{ playlist.defaultChar }}</td>
        </tr>
      </tbody>
    </table>

    <h3 class="title is-3">Done playlists</h3>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>Default</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(playlist, ix) in donePlaylists" :key="ix">
          <td><router-link :to="playlist.link">{{ playlist.name }}</router-link></td>
          <td class="col-icon">{{ playlist.defaultChar }}</td>
        </tr>
      </tbody>
    </table>
    <rp-button @click="moreDonePlaylists">More</rp-button>
  </div>
</template>

<script>
import orderBy from 'lodash.orderby'

export default {
  name: 'Playlsts',
  data () {
    return {
      playlists: [],
      donePage: 1
    }
  },
  computed: {
    currentPlaylists () {
      return this.playlists
        .filter(x => !x.isDone)
    },
    donePlaylists () {
      const playlistsSorted = orderBy(this.playlists, ['createdAtTs'], ['desc'])
      return playlistsSorted
        .filter(x => x.isDone)
        .reduce((playlists, playlist) => {
          if (playlists.length >= this.donePage * 10) {
            return playlists
          }

          playlists.push(playlist)

          return playlists
        }, [])
    }
  },
  mounted () {
    this.fetchPlaylists()
  },
  methods: {
    fetchPlaylists () {
      this.$api.get('/api/ytlist/playlist/list')
        .then(response => {
          this.playlists = response.data.playlists
            .map(p => {
              const link = { name: 'ytlist/playlist-details', params: { id: p.id } }
              const defaultChar = p.isDefault ? '✔' : null
              return {
                ...p,
                link,
                defaultChar,
                createdAtTs: this.convertToTs(p.createdAt)
              }
            })
        })
    },
    convertToTs (dStr) {
      if (dStr == null) {
        return 0
      }

      const d = new Date(dStr)
      return d.getTime()
    },
    gotoAdd () {
      this.$router.push({ name: 'ytlist/playlist-create' })
    },
    moreDonePlaylists () {
      this.donePage++
    }
  }
}
</script>
