<template>
  <div>
    <rp-form-field v-model="name" label="Name" />
    <rp-form-field v-model="externalId" label="External ID" />
    <rp-form-field v-model="division" label="Division" />
    <div v-if="playlistId === null" class="playlist-info">
      <rp-button :loading="loading" @click="createPlaylist">Create playlist</rp-button>
    </div>
    <rp-button color="primary" :loading="loading" @click="update">Update</rp-button>
  </div>
</template>

<script>
export default {
  name: 'EventEdit',
  props: {
    id: [String, Number]
  },
  data () {
    return {
      name: null,
      externalId: null,
      division: null,
      playlistId: null,
      data: null,
      loading: false
    }
  },
  mounted () {
    this.fetchEvent()
  },
  methods: {
    fetchEvent () {
      return this.$api.get('/api/ytlist/event/' + this.id)
        .then(response => {
          this.name = response.data.name
          this.externalId = response.data.externalId
          this.division = response.data.division
          this.playlistId = response.data.playlistId
          this.data = response.data.data
          this.info = response.data
        })
    },
    update () {
      if (this.name == null || this.name === '') {
        return
      }

      const model = {
        id: this.id,
        name: this.name,
        externalId: this.externalId,
        division: this.division,
        data: this.data
      }
      this.loading = true
      this.$api.put('/api/ytlist/event/' + this.id, model)
        .then(response => {
          this.loading = false
          this.$router.replace({ name: 'ytlist/event-details', props: { id: this.id } })
        })
    },
    createPlaylist () {
      this.loading = true
      this.$api.post('/api/ytlist/event/playlist/' + this.id, { id: this.id, name: this.name })
        .then(response => {
          this.playlistId = response.data.playlistId
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.playlist-info {
  margin-bottom: 32px;
}
</style>
