<template>
  <div>
    <div v-if="channel">
      <router-link :to="channelLink">Channel</router-link>
    </div>

    <channel-items
      :playlistData="playlistData"
      :applyCensor="applyCensor"
      @loadPage="loadPage" />
  </div>
</template>

<script>
import ChannelItems from './ChannelItems.vue'

export default {
  inheritAttrs: false,
  components: {
    ChannelItems
  },
  props: {
    channel: Object,
    playlistData: Object,
    applyCensor: Boolean
  },
  computed: {
    channelLink () {
      return { name: 'ytlist/channel-details', params: { id: this.channel.id } }
    }
  },
  methods: {
    loadPage (token) {
      this.$emit('loadPage', token)
    }
  }
}
</script>
