<template>
  <div>
    <h3 class="title is-3">{{ channelName }}</h3>
    <div><rp-checkbox label="Censor" v-model="applyCensor" /></div>
    <table class="table is-fullwidth video-list" :class="{ censored: applyCensor }" ref="videoList">
      <thead>
        <tr>
          <th>Video name</th>
          <th>Published</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="video in videos" :key="video.id">
          <td class="video-title">
            <div><censored-name :censored="applyCensor" :name="video.name" /></div>
            <div v-if="defaultPlaylistName != null"><a v-if="video.showAdd" href="#" @click.prevent="addToDefault(video)">Add to "{{ defaultPlaylistName }}"</a></div>
          </td>
          <td class="video-date">{{ formatDate(video.publishedAt) }}</td>
          <td><rp-button :link="getVideoLink(video)" mode="ICON" iconSize="small" iconStyle="fab" icon="youtube" :hideLabel="true" @click.prevent="showVideo(video)" /></td>
        </tr>
      </tbody>
    </table>
    <div>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a :disabled="!hasPrevPage" @click="prev" class="pagination-previous">&lt; Prev page</a>
        <a :disabled="!hasNextPage" @click="next" class="pagination-next">Next page &gt;</a>
      </nav>
    </div>
    <record-selections v-if="recordEnabled && videos.length > 0" :listRef="$refs.videoList" :channelId="id" :page="currentPage" />
  </div>
</template>

<script>
import { parseVideoName } from '../components/videoHelper'
import RecordSelections from './RecordSelections.vue'
import CensoredName from '../components/CensoredName.vue'

export default {
  name: 'ChannelDetails',
  components: {
    RecordSelections,
    CensoredName
  },
  props: {
    id: {
      type: [Number, String]
    },
    page: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      applyCensor: true,
      channelName: null,
      channelVideoCount: 0,
      pageSize: 20,
      videos: [],
      recordEnabled: false,
      defaultPlaylistName: null
    }
  },
  computed: {
    channelId () {
      return typeof this.id === 'number' ? this.id : parseInt(this.id, 10)
    },
    currentPage () {
      return typeof this.page === 'number' ? this.page : parseInt(this.page, 10)
    },
    hasPrevPage () {
      return this.currentPage > 0
    },
    hasNextPage () {
      return this.currentPage * (this.pageSize + 1) < this.channelVideoCount
    }
  },
  watch: {
    page () {
      this.fetchChannelVideos()
    }
  },
  mounted () {
    this.fetchChannelInfo()
    this.fetchChannelVideos()
    this.fetchDefaultPlaylist()
  },
  methods: {
    fetchChannelInfo () {
      this.$api.get('/api/ytlist/channel/' + this.channelId)
        .then(response => {
          this.channelName = response.data.name
          this.channelVideoCount = response.data.videoCount
        })
    },
    fetchChannelVideos () {
      const data = {
        channelId: this.channelId,
        skip: this.currentPage * this.pageSize,
        take: this.pageSize
      }
      this.$api.post('/api/ytlist/channel-videos', data)
        .then(response => {
          this.videos = response.data.videos
            .map(video => {
              const info = parseVideoName(video.name)
              const sensoredName = info.sensoredName
              return {
                sensoredName,
                info,
                ...video,
                showAdd: true
              }
            })
        })
    },
    fetchDefaultPlaylist () {
      this.$api.get('/api/ytlist/playlist/default')
        .then(response => {
          this.defaultPlaylistName = response.data.name
        })
    },
    prev () {
      const page = this.currentPage - 1
      this.$router.push({ name: 'ytlist/channel-details', params: { id: this.channelId, page: page } })
    },
    next () {
      const page = this.currentPage + 1
      this.$router.push({ name: 'ytlist/channel-details', params: { id: this.channelId, page: page } })
    },
    formatDate (d) {
      return d.substring(0, 10)
    },
    formatName (n) {
      return n
    },
    addToDefault (vid) {
      vid.showAdd = false
      this.$api.post('/api/ytlist/playlist/add-to-default', { id: vid.id })
    },
    getVideoLink (v) {
      const props = this.$router.resolve({
        name: 'ytlist/ytvideo',
        params: { id: v.externalId }
      })

      return props.href
    },
    showVideo (v) {
      this.$router.push({ name: 'ytlist/ytvideo', params: { id: v.externalId } })
    }
  }
}
</script>
