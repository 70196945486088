<template>
  <div>
    <rp-button @click="gotoEdit">Edit</rp-button>
    <rp-button @click="sync">Sync</rp-button>
    <h1 class="title">{{ name }}</h1>
    <rp-button v-if="playlistLink != null" :link="playlistLink" @click.prevent="openPlaylist">Playlist</rp-button>
    <template v-if="showEventHeader">
      <event-header
        :eventId="eventId"
        :event="info"
        :eventData="eventData"
        :roundsData="roundsData"
        :divisionData="divisionData" />
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EventHeader from './components/EventHeader'

export default {
  name: 'EventDetails',
  components: {
    EventHeader
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(
      'ytlist/currentEvent', {
        info: 'getEventInfo',
        eventData: 'getEventData',
        roundsData: 'getRoundsData'
      }
    ),
    eventId () {
      if (this.id != null) {
        if (typeof this.id === 'string') {
          return parseInt(this.id, 10)
        } else {
          return this.id
        }
      }
      return null
    },
    name () {
      return this.info?.name
    },
    divisionData () {
      return this.eventData?.data.Divisions.find(x => x.Division === this.info.division)
    },
    showEventHeader () {
      return this.info != null && this.roundsData != null
    },
    playlistLink () {
      if (this.info?.playlistId == null) {
        return null
      }

      const props = this.$router.resolve({
        name: 'ytlist/playlist-details',
        params: { id: this.info.playlistId }
      })

      return props.href
    }
  },
  mounted () {
    this.fetchEvent()
  },
  methods: {
    ...mapActions(
      'ytlist/currentEvent', [
        'loadEventData'
      ]
    ),
    fetchEvent () {
      return this.loadEventData(this.eventId)
    },
    gotoEdit () {
      this.$router.replace({ name: 'ytlist/event-edit', params: { id: this.eventId } })
    },
    sync () {
      return this.$api.post('/api/ytlist/event/' + this.eventId + '/sync')
        .then(response => {
          return this.fetchEvent()
        })
    },
    openPlaylist () {
      this.$router.push({
        name: 'ytlist/playlist-details',
        params: { id: this.info.playlistId }
      })
    }
  }
}
</script>
