<template>
  <div>
    <rp-button @click="gotoSync">Sync</rp-button>
    <rp-button @click="gotoSearch">Search</rp-button>
    <rp-button @click="gotoChannels">Channels</rp-button>
    <rp-button @click="gotoPlaylists">Playlists</rp-button>
    <rp-button @click="gotoNotes">Notes</rp-button>
    <rp-button @click="gotoEvents">Events</rp-button>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    gotoSync () {
      this.$router.push({ name: 'ytlist/sync' })
    },
    gotoSearch () {
      this.$router.push({ name: 'ytlist/search' })
    },
    gotoChannels () {
      this.$router.push({ name: 'ytlist/channel-list' })
    },
    gotoPlaylists () {
      this.$router.push({ name: 'ytlist/playlist-list' })
    },
    gotoNotes () {
      this.$router.push({ name: 'ytlist/notes-list' })
    },
    gotoEvents () {
      this.$router.push({ name: 'ytlist/event-list' })
    }
  }
}
</script>
