<template>
  <div>
    <rp-button @click="showNextHole" :disabled="!hasMoreHoles">{{ currentHoleFormatted }}</rp-button>
    <HoleStats :round="currentRound" :hole="currentHole" :holeTotal="lastHole" />
    <h5 class="subtitle">Leaderboard - {{ currentRoundOrdinalFormatted }}</h5>
    <table class="table is-bordered is-narrow is-fullwidth">
      <tr>
        <th class="has-background-highlight">Pos</th>
        <th class="has-background-highlight">Name</th>
        <th class="has-background-highlight">Rd</th>
        <th class="has-background-highlight">Thru</th>
        <th class="has-background-highlight" colspan="2">Total</th>
      </tr>
      <tr v-for="player in leaderboard" :key="player.scoreId">
        <td class="has-background-highlight">{{ player.position }}</td>
        <td class="score-name" :class="{ dnf: player.dnf }">{{ player.name }}<RoundScores :scores="player.roundScores" :roundHoleCount="lastHole" /></td>
        <td>{{ player.roundScore }}</td>
        <td>{{ player.playedHoles }}</td>
        <td>{{ player.totalScore }}</td>
        <td><rp-button mode="ICON" iconSize="small" iconStyle="fas" icon="arrow-right" :hideLabel="true" label="Reveal hole score" @click="nextPlayerHole(player)" /></td>
      </tr>
      <tr>
        <td colspan="6">
          <rp-button @click="increasePlayerLimit" :disabled="!hasMorePlayers">More...</rp-button>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import orderBy from 'lodash.orderby'
import RoundScores from '../components/RoundScores.vue'
import HoleStats from '../components/HoleStats.vue'

export default {
  name: 'RoundDetails',
  components: {
    RoundScores,
    HoleStats
  },
  props: {
    id: [String, Number],
    roundId: [String, Number]
  },
  data () {
    return {
      playerRevealedScores: [], // [{ id, revealedHole }]
      playerLimit: 20
    }
  },
  computed: {
    ...mapGetters(
      'ytlist/currentEvent', {
        info: 'getEventInfo',
        eventData: 'getEventData',
        roundsData: 'getRoundsData',
        currentRoundOrdinal: 'getCurrentRoundOrdinal',
        currentHole: 'getRevealedEventRoundHoles'
      }
    ),
    currentRoundOrdinalFormatted () {
      if (this.currentRoundOrdinal === this.roundsData.length) {
        return 'Final round'
      }

      return 'Round ' + this.currentRoundOrdinal
    },
    currentRound () {
      return this.roundsData
        .map(r => r.data)
        .find(r => r.live_round_id.toString() === this.roundId.toString())
    },
    hasMoreHoles () {
      return this.currentHole < this.lastHole
    },
    lastHole () {
      // Can layouts have more than one element?
      return this.currentRound.layouts[0].Detail.length
    },
    allPlayers () {
      return this.currentRound.scores
        .map(score => {
          const pars = score.Pars.split(',').map(x => parseInt(x))
          const scores = score.Scores.split(',').map(x => parseInt(x))
          let dnf = false
          let roundScore = 0
          const playedHoles = Math.max(this.currentHole, this.getPlayerHole(score.ScoreID))
          let teeStart = parseInt(score.TeeStart)
          if (isNaN(teeStart)) {
            teeStart = 1
          }
          teeStart = teeStart - 1
          const roundScores = []

          for (let i = 0; i < playedHoles; i++) {
            const ix = (i + teeStart) % 18

            const par = pars[ix]
            const holeScore = scores[ix]

            if (!isNaN(holeScore)) {
              roundScores.push({ throws: holeScore, parDiff: holeScore - par })
              roundScore += holeScore - par
            } else {
              dnf = true
            }
          }

          const dnq = scores.every(x => isNaN(x))
          if (dnf) {
            dnf = false
          }

          let lastRoundScore = 0 // Rounds
          let roundStartScore = 0
          if (this.currentRoundOrdinal > 1) {
            const prevRoundScores = score.Rounds.split(',').filter(x => x !== '').map(x => parseInt(x))
            // find round pars
            for (let i = 1; i < this.currentRoundOrdinal; i++) {
              const roundIx = i - 1
              const roundPar = this.roundsData[roundIx].data.holes.reduce((sum, hole) => sum + hole.Par, 0)
              lastRoundScore = prevRoundScores[roundIx] - roundPar
              roundStartScore += lastRoundScore
            }
          }

          const totalScore = roundStartScore + roundScore

          return {
            position: null,
            scoreId: score.ScoreID,
            name: score.ShortName,
            totalScore,
            roundScore,
            roundStartScore,
            lastRoundScore,
            playedHoles,
            pdgaNum: score.PDGANum,
            pars,
            scores,
            dnf,
            dnq,
            roundScores
          }
        })
    },
    currentHoleFormatted () {
      if (!this.hasMoreHoles) {
        return 'End of round'
      } else {
        return 'Show results for hole ' + (this.currentHole + 1)
      }
    },
    leaderboard () {
      const players = orderBy(
        this.allPlayers.filter(x => !x.dnq),
        ['totalScore', 'roundScore', 'lastRoundScore', 'pdgaNum'], ['asc', 'asc', 'asc', 'asc']
      ).slice(0, this.playerLimit)

      // Set player positions
      for (let i = 0; i < players.length; i++) {
        const player = players[i]

        if (i > 0) {
          const lastPlayer = players[i - 1]
          if (player.totalScore === lastPlayer.totalScore) {
            if (lastPlayer.position[0] === 'T') {
              player.position = lastPlayer.position
            } else {
              player.position = 'T' + lastPlayer.position
              lastPlayer.position = player.position
            }
          } else {
            player.position = (i + 1).toString()
          }
        } else {
          player.position = '1'
        }
      }

      return players
    },
    hasMorePlayers () {
      return this.allPlayers.length > this.playerLimit
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations(
      'ytlist/currentEvent', {
        increasedRevealedEventRoundHoles: 'increasedRevealedEventRoundHoles',
        setCurrentRoundOrdinal: 'setCurrentRoundOrdinal'
      }
    ),
    init () {
      for (let i = 0; i < this.roundsData.length; i++) {
        const roundData = this.roundsData[i].data
        if (roundData.live_round_id.toString() === this.roundId.toString()) {
          this.setCurrentRoundOrdinal(i + 1)
        }
      }
    },
    showNextHole () {
      this.increasedRevealedEventRoundHoles()
    },
    getPlayerHole (scoreId) {
      return this.playerRevealedScores.find(x => x.id === scoreId)?.revealedHole ?? 0
    },
    nextPlayerHole (scoreInfo) {
      const holeInfo = this.playerRevealedScores.find(x => x.id === scoreInfo.scoreId)
      if (holeInfo == null) {
        this.playerRevealedScores.push({ id: scoreInfo.scoreId, revealedHole: this.currentHole + 1 })
      } else {
        holeInfo.revealedHole = Math.max(this.currentHole + 1, holeInfo.revealedHole + 1)
      }
    },
    increasePlayerLimit () {
      const nextLimit = Math.min(this.allPlayers.length, this.playerLimit + 10)
      this.playerLimit = nextLimit
    }
  }
}
</script>
