<template>
  <div>
    <rp-form-field v-model="title" label="Title" />
    <rp-button color="primary" :loading="loading" @click="create">Create</rp-button>
  </div>
</template>

<script>
export default {
  name: 'NotesCreate',
  data () {
    return {
      title: null,
      loading: false
    }
  },
  methods: {
    create () {
      if (this.title == null || this.title === '') {
        return
      }

      const model = {
        title: this.title
      }
      this.loading = true
      this.$api.post('/api/ytlist/notes', model)
        .then(response => {
          this.loading = false
          this.$router.push({ name: 'ytlist/notes-list' })
        })
    }
  }
}
</script>
