<template>
  <div>
    <rp-button @click="gotoEdit">Edit</rp-button>
    <h1 class="title">{{ title }}</h1>
    <pre>{{ data }}</pre>
  </div>
</template>

<script>
export default {
  name: 'NotesDetails',
  props: {
    id: [String, Number]
  },
  data () {
    return {
      info: null
    }
  },
  computed: {
    title () {
      return this.info?.title
    },
    data () {
      return this.info?.data
    }
  },
  mounted () {
    this.fetchNotes()
  },
  methods: {
    fetchNotes () {
      this.$api.get('/api/ytlist/notes/' + this.id)
        .then(response => {
          this.info = response.data
        })
    },
    gotoEdit () {
      this.$router.replace({ name: 'ytlist/notes-edit', params: { id: this.id } })
    }
  }
}
</script>
