<template>
  <div>
    <dictionary-table :dictionary="dict" />
  </div>
</template>

<script>
import DictionaryTable from '../../components/DictionaryTable'

export default {
  name: 'EventHeader',
  components: {
    DictionaryTable
  },
  props: {
    eventId: Number,
    event: Object,
    divisionData: Object,
    eventData: Object,
    roundsData: Array
  },
  data () {
    return {
      info: null
    }
  },
  computed: {
    dict () {
      if (this.eventData == null) {
        return []
      }

      return [
        { key: 'Id', value: this.event.externalId },
        { key: 'Name', value: this.eventData.data.Name },
        { key: 'Location', value: this.eventData.data.Location },
        { key: 'Rounds', value: this.eventData.data.Rounds },
        { key: 'Division', value: this.divisionData.Division },
        { key: 'Players', value: this.divisionData.Players },
        { key: 'Date', value: this.eventData.data.StartDate }
      ]
    }
  },
  mounted () {
    this.ensureUserEvent()
  },
  methods: {
    ensureUserEvent () {
      this.fetchUserEvent()
    },
    fetchUserEvent () {
      return this.$api.get('/api/ytlist/user-event/event/' + this.eventId)
        .then(response => {
          this.info = response.data
        })
    }
  }
}
</script>
