<template>
  <div>
    <rp-button @click="gotoEdit">Edit</rp-button>
    <h1 class="title">{{ name }}</h1>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th colspan="2">Playlist items</th>
          <th>Published</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, ix) in playlistItems" :key="ix">
          <td>{{ watchStatus(item) }}</td>
          <td>
            <div><censored-name :censored="isCensored(item)" :name="item.name" /></div>
            <div>{{ item.channelName }}</div>
            <div><router-link :to="detailsLink(item)">Details</router-link></div>
          </td>
          <td>{{ formatDate(item.publishedAt) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CensoredName from '../components/CensoredName'

export default {
  name: 'PlaylstDetails',
  components: {
    CensoredName
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      info: null,
      userInfo: null
    }
  },
  computed: {
    name () {
      return this.info?.name
    },
    playlistItems () {
      return this.info?.items ?? []
    },
    userItemInfo () {
      return this.userInfo?.items ?? []
    },
    playlistIsCensored () {
      return this.userInfo?.isCensored ?? false
    }
  },
  mounted () {
    this.fetchPlaylist()
  },
  methods: {
    fetchPlaylist () {
      this.fetchPlaylistUserInfo()
      this.$api.get('/api/ytlist/playlist/details/' + this.id)
        .then(response => {
          this.info = response.data
        })
    },
    fetchPlaylistUserInfo () {
      this.$api.get('/api/ytlist/playlist/list-user-props/' + this.id)
        .then(response => {
          this.userInfo = response.data
        })
    },
    gotoEdit () {
      this.$router.replace({ name: 'ytlist/playlist-edit', params: { id: this.id } })
    },
    detailsLink (item) {
      return { name: 'ytlist/playlist-item', params: { id: item.id } }
    },
    isCensored (item) {
      if (!this.playlistIsCensored) {
        return false
      }

      return !this.isWatched(item)
    },
    isWatched (item) {
      return this.userItemInfo.find(x => x.itemId === item.id)?.watched
    },
    isInProgress (item) {
      return this.userItemInfo.find(x => x.itemId === item.id)?.progress > 0
    },
    watchStatus (item) {
      return this.isWatched(item)
        ? '✔'
        : this.isInProgress(item)
          ? '💨'
          : null
    },
    formatDate (d) {
      return d.substring(0, 10)
    }
  }
}
</script>
