<template>
  <div class="container" name="subModule">
    <div class="module-container ytlist">
      <router-view />
    </div>
    <yt-api />
  </div>
</template>

<script>
import YtApi from './YtApi'

export default {
  name: 'YtListModule',
  components: {
    YtApi
  },
  mounted () {
    this.$store.dispatch('ytlist/loadAll')
  }
}
</script>
